@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Source Sans Pro", Helvetica, Arial, 游ゴシック体, YuGothic,
    メイリオ, Meiryo, sans-serif;
  scrollbar-width: none;
}

input[type="text"],
input[type="number"],
textarea {
  font-size: 16px;
}

.markdown-box {
  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #4f5256;
    line-height: 1.5em;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #4f5256;
    line-height: 1.5em;
    margin-bottom: 1em;
  }

  h3 {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.4em;
    color: #333;
    margin-bottom: 1em;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4em;
    color: #333;
    margin-bottom: 0.5em;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 0.5em;
  }

  img {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.item-description {
  img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  a {
    text-decoration: underline;
    color: #1eaebf;
  }
}

.size-description {
  img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  a {
    text-decoration: underline;
    color: #1eaebf;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #4f5256;
    line-height: 1.5em;
  }

  h2 {
    font-size: 17px;
    font-weight: 700;
    color: #4f5256;
    margin-bottom: 0.5em;
  }

  h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 0.5em;
    font-weight: 600;
  }
}

.markdown-seo-text {
  margin-top: 12px;

  h2 {
    margin: 30px 0 35px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #424242;
    border-left: solid 4px #4f5256;
    padding: 0px 5px;
  }
}

.article-feature-text {
  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.article-body {
  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  /* 番号付きリストを表示 */
  ol {
    list-style: decimal;
    margin-left: 8px;
  }
}

/* 利用規約・プライバシーポリシー */
.template-text {
  /* @extend .markdown-box; */
  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #4f5256;
    line-height: 1.5em;
    margin-top: 1.4em;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #4f5256;
    line-height: 1.5em;
    margin-top: 1.4em;
    margin-bottom: 1em;
  }

  h3 {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.4em;
    color: #333;
    margin-top: 1.4em;
    margin-bottom: 1em;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4em;
    color: #333;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-left: 0.5em;
    white-space: pre-line;
  }

  img {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  ol {
    margin-left: 1.5em;
    /* list-style: outside; */
    list-style-position: none;
    counter-reset: list1;

    li {
      counter-increment: list1;

      &:before {
        content: "" counter(list1) ". ";
        margin: 0px 0px 0px -16px;
      }

      ol {
        list-style: none;
        counter-reset: list2;

        li {
          counter-increment: list2;

          &:before {
            content: "(" counter(list2) ") ";
            margin: 0px 0px 0px -24px;
          }
        }
      }
    }
  }

  ul {
    margin-left: 1.5em;
    li {
      ol {
        list-style: none;
        counter-reset: list2;

        li {
          counter-increment: list2;

          &:before {
            content: "(" counter(list2) ") ";
            margin: 0px 0px 0px -24px;
          }
        }
      }
    }
  }
}

/* プライバシーポリシー */
.template-text-privacy {
  counter-reset: list-h2;
  h2 {
    margin-left: 1em;
    counter-increment: list-h2;
    &:before {
      content: "" counter(list-h2) ". ";
      margin: 0px 0px 0px -24px;
    }
  }
  ul {
    margin-left: 1.5em;
    list-style: circle;
  }
}
